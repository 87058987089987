.inventory-container {
  /* background-color: rgb(0, 0, 0); */
  /* padding-top: 10px; */
  margin-top: 20px;
  margin: 10px 10px 0 10px;
  /* color: rgb(0, 163, 63); */
}
.inventory-container {
  /* background-color: #2f00ff83; */
  /* padding: 0; */
  /* padding-top: 70px; */
  /* color: rgb(0, 163, 63); */
}
.inventory-table {
  /* height: auto; */
  /* background-color: #ffffff; */
  /* padding: 0 20px 20px 20px; */
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
  background-color: 'yellow' !important;
}

.inventory-table th,
.inventory-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
  padding: 0 10px;
}

.inventory-table .MuiTableBody-root .MuiTableRow-root:not(:last-child):hover {
  box-shadow: 0 0 0 2px green inset;
  background-color: rgba(0, 128, 0, 0.491);
}
.selectedRow {
  box-shadow: 0 0 0 2px green inset;
  background-color: rgba(0, 128, 0, 0.491);
}
.large-size-inventory {
  background-color: #121212;
  position: absolute;
  /* top: 50%; */
  width: 100%;
}
/*
  
  
  @media BEGIN================================
  
  
  */

@media (min-width: 1024px) {
  /* .large-size-inventory {
    position: relative;
    top: -200px;
    margin-top: 70px;
  } */
}

/*  END 1024px----------------------------------------------------------  




1600px -----------------------------------------------------------------
*/
@media (min-width: 1600px) {
  .inventory-table th,
  .inventory-table td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
  }
  .large-size-inventory {
    position: unset;
    top: unset;
    width: unset;
  }
}
/* END 1600px----------------------------------------------------------  

*/
